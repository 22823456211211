.workCodeCard{
    max-height: 405px;
    overflow-y: auto;
    overflow-x: hidden;
}

.hrTag{
    margin-top: 5px;
    margin-bottom: 5px;
}

.reportCard{
    overflow-x: hidden;
    overflow-y: auto;
}