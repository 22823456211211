.container_delete {
  max-width: 42rem;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.heading_delete {
  color: #0056b3;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.steps_delete {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.step_delete {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-text {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.step-image {
  width: 30%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}
