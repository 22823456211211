.sidenav {
    height: calc(100vh - 56px);
    /* height: 100vh; */
    width: 0;
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: #fbfbfb;
    overflow-x: hidden;
    transition: 0.2s;
    padding-top: 60px;
    overflow: hidden;
}

.sidenav-section {
    width: 93%;
    margin: auto;
}

.sidenav a:hover {
    color: black;
}

.sidenav .closebtn {
    position: absolute;
    top: -14px;
    right: 18px;
    font-size: 32px;
    color: #595959;
    cursor: pointer;

}

.overlay-content {
    padding: 0px 8px 4px 2px;
    text-decoration: none;
    font-size: 15px;
    color: black;
    display: block;
    transition: 0.1s;
    /* margin-bottom: 4rem; */
    /* border: 1px solid red; */
    height: 87vh;
    overflow: auto;
    overflow-x: hidden;
}

.modal-title {
    padding-left: 10px;
    font-weight: bolder;
}

.sideNav-row {
    padding-top: 6px;
    padding-bottom: 3px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}