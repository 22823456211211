.date-width {
    width: 100%;
    padding-left: 9px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5b626b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  /* .react-datepicker__triangle {
    display: none;
  }
  
  .react-datepicker {
    margin-bottom: 10px; 
  } */

  /* .react-datepicker__tab-loop{
    position: fixed;
  } */

  .parent-container {
    position: relative;
  }
  
  .custom-datepicker-container {
    position: absolute;
    top: -100%; /* Adjust as needed to ensure it appears above the input */
    z-index: 999; /* Ensures it stays on top of other elements */
  }
  
  .react-datepicker-popper {
    z-index: 9999 !important; /* Ensure highest z-index for visibility */
  }