.invoiceReportClass>div:nth-child(2)>div>div>table>tbody>tr>td{
   text-align: center;
}

.invoiceReportClass>div:nth-child(2)>div>div>table>thead>tr>th{
   text-align: center;
}

.invoiceReportClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(5),
.invoiceReportClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(6),
.invoiceReportClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(3),
.invoiceReportClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(4)
{
   text-align: right;
}