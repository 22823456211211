.row {
  padding: 8px 13px;
  .col-3 {
    display: flex;
    // align-items: center;
    // padding: 8px;
    span {
      display: flex;
      align-items: center;
      input {
      }
      label {
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
}

.col-12{
    padding: 8px;
}
.form-group{
    margin-bottom: 0px !important;
}
