.calendertable {
    overflow-x: scroll;
}

.sticky-thc table {
    text-align: center;
    width: 100%;

    th {
        background: #f8f8f8;
        font-weight: bold;
    }

    th,
    td {
        border-bottom: 1px solid #ddd;
        margin: 0;
        padding: 15px 10px;
    }
}


.sticky-thc {

    th
    {
        width: 220px;
    }
    // thead {
    //     position: absolute;
    //     top: 0px;
    //     height: 92px;
    //     z-index: 999;
    //     width: auto;
    //     width: 210px;
    // }
}

table.sticky-thc tbody, table.sticky-thc thead
{
    display: block;
}

table.sticky-thc tbody 
{
   overflow: auto;
   height: 70vh;
}

