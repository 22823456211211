.setting-card{
    padding:15px 20px;
    /* border: 1px solid black; */
    margin: 5px 10px;
    border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0;
    background-color: #f8f9fc;
    border: 1px solid #e3e6f0;
    display:flex;
    align-items:center;
    cursor: pointer;
}
.setting-card-title{
    color: #4e73df;
}
.setting-card-title:hover{
    text-decoration: underline;
}