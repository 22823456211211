.small-loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid black;
    width: 25px;
    height: 25px;
    -webkit-animation: simple-spin 2s linear infinite; /* Safari */
    animation: simple-spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes simple-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes simple-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
