.container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 40px;
    color: #333;
}

.heading {
    color: #0056b3;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.subheading {
    color: #0056b3;
    font-size: 1.5rem;
    margin-top: 20px;
}

p {
    margin: 10px 0;
}

ul {
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

.link {
    color: #0056b3;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}
