.create-order-modal-header{
    display: flex;
    align-items: center;
    width: 100%;
    .create-modal-close-icon{
        margin-left: auto;
        cursor: pointer;
    }
}

.create-order-modal-body{
    width: 100%;
    .modal-body-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 12px;
        select{
            width: 60%;
        }
    }
}