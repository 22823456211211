.singleStaffingCard {
    width: 210px;
    height: 95px;
    color: #0878ff;
    cursor: pointer;
}

.singleStaffingCard_heading:hover {
    text-decoration: underline;
}

.errorMessage {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    background: rgba(255, 0, 0, 0.15);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 800;
    color: green;
}

.errorMessage > div {
    position: absolute;
    top: 35px;
}