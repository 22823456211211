.react-multi-checkbox{
    width: 100%;

}

.react-multi-checkbox > div> button{
    width: 100%;
    border: 1px solid #d3d3d3;
    
}

.react-multi{
    border: 1px solid #adaaaa;
    border-radius: 3px;
}


[id^="react-select-"] {
    display: flex !important;
}