// .setup-header-divider{
//     background-color: grey;
//     width: 100%;
// }
// .card{
//     border: 1px solid gray;
// }

.card {
    border: 1px solid rgba(128, 128, 128, 0.5) !important;
}
