.invoiceTableClass>div:nth-child(2)>div>div>table>thead>tr>th:nth-child(1){
   min-width: 80px !important;
}

.invoiceTableClass>div:nth-child(2)>div>div>table>tbody>tr>td{
   text-align: center;
}

.invoiceTableClass>div:nth-child(2)>div>div>table>thead>tr>th{
   text-align: center;
}

.invoiceTableClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(5),
.invoiceTableClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(6),
.invoiceTableClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(7),
.invoiceTableClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(8)
{
   text-align: right;
}

.invoiceCard{
   overflow-y: auto !important; 
   overflow-x: hidden !important;
   min-height: 250px;
}