body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col-auto{
  padding-left: 0px !important;
}

.form-control:disabled {
  background-color: #e0e0e0 !important;
}

.abc{
  background-color: red
}

.bcd{
  background-color:blue;
}
.checkbox-style{
  position: relative !important;
}
.checkbox-size{
  height: 20px !important;
}
.allOptions{
  font-size: 20px;
  font-weight: 900;
}

/* dataTable */
tbody + thead{
  display: none;
}

/* card */
.card-header{
  background-color:#f8f9fc;
  border-bottom: 1px solid #d0d0d0;
  font-size:18px;
  font-weight: bold;
  color: #507ae2;
}

body{
    color: #000000 !important;
    font-size: 1rem !important;
    font-family: Poppins, sans-serif !important;
}
span{
  font-weight: 500 !important;
}

input[type="radio"],input[type="checkbox"] {
  height: calc(1.5em);
}

.card-animate {
  animation: pulse 3s infinite;
}

.label-class{
  margin-bottom: 0 !important;
}

.removeButtonCss{
  background: no-repeat;
  border: none;
}

.addBtn{
  background: #27bf27;
  color: white;
  border-radius: 50%;
}
.deleteBtn{
  background: red;
  color: white;
  border-radius: 50%;
}
@keyframes pulse {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #9bd83f;
  }
  100% {
    background-color: #ffffff;
  }
}
