@import "../../../../common.scss";

%billing-div {
  border: 1px solid black;
  padding: 5px 10px;
  width: 300px;
  font-size: 13px;
  font-weight: 900;
}
.general-form{
  max-height: 72vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}



.timeDiff {
  font-weight: 800 !important;
}

%input-select-disabled-style {
  background-color: #eaecf4;
}

.loading-spinner {
  text-align: end;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 2px;
}

.billing-div {
  display: flex;
  margin-left: 20px;

  div {
    &:nth-last-child(1) {
      @extend %billing-div;
    }

    &:nth-last-child(2) {
      @extend %billing-div;
      color: black;
    }
  }
}

.flex-div-config {
  @extend %flex-config;
}

.contracters-dropdown {
  width: 90%;
  cursor: pointer;
}

.select-style {
  @extend %input-select-disabled-style;
}

.myStyle {
  background-color: red;
}

.checkbox-label-style {
  @extend %flex-config;
}

.checkbox-label-style-m {
  margin-left: 10px;
  margin-top: 10px;
}

.styled-checkbox {
  display: flex;
  align-items: center;

  label {
    margin: 0px 0px 0px 5px;
    cursor: pointer;
  }
}

.input-group-prepend {
  cursor: pointer;

  div {
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      width: 15px;
      height: 15px;
      border: 1px solid white;
      color: white;
      border-radius: 50%;
      padding: 2px;
      justify-content: center;
    }
  }
}

.staffing-level {
  margin-bottom: 0;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}

.staffing-flex {
  display: flex !important;
  flex-direction: column;
  align-items: baseline !important
}


.staffing-bonus {
  width: 80%;
  margin-left: 10% !important;
}

.form-general{
  padding: 3px 5px !important;
  margin: 0;
  font-size: 12px !important;
  height: fit-content !important;
}

.staffing-level{
  font-size: 12px;
}

.styled-checkbox label{
  font-size: 12px;
}

.row-general{
  padding: 3.5px 13px !important;
}