@import "../../../../common.scss";
.row {
  .col-3 {
  }
  .col-12 {
      //Table style
    table {
      cursor: pointer;
      @extend %table-style;
      tr,
      td,
      th {
        @extend %table-head-row;
      }
    }
  }
}
