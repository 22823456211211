@import "../../../../common.scss";
.col-12 {
    overflow: auto;
    table {
        @extend %table-style;
    }
}

.checkbox-width{
    transform: scale(1.5);
    width: fit-content !important;
}

.custom-title{
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
}
