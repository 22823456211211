.create-new-order-div {
  display: flex;
  align-items: center;
  width: 100%;

  select {
    width: 60%;
    margin: 0px 10px;
  }
}

.client-topbar-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .client-topbar-main-container {
    display: flex;
    align-items: center;
    width: 60%;

    .client-topbar-title {
      font-size: 26px;
      margin: 0px 10px;
    }

    .client-topbar-select {
      width: 50%;
      margin: 10px;
    }

    .client-topbar-buttons {
      display: flex;
      justify-content: space-between;
      width: 20%;

    }
  }
}

.dot {
  height: 9px;
  width: 9px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}


.live {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 900;
}