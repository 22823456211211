.invoiceClass>div:nth-child(2)>div>div>table>tbody>tr>td{
   text-align: center;
}

.invoiceClass>div:nth-child(2)>div>div>table>thead>tr>th{
   text-align: center;
}

.invoiceClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(3),
.invoiceClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(4),
.invoiceClass>div:nth-child(2)>div>div>table>tbody>tr>td:nth-child(5)
{
   text-align: right;
}