.parent-wrapper{
    
    .table-box-container{
        .table-card{
            
            .table-row{
                padding: 0px 13px !important;
                font-size: 16px;
                .first-section{
                    border: 1px solid #e3e6f0;
                    border-right: none;
                    

                }
                .second-section{
                    border: 1px solid #e3e6f0;

                }
                .third-section{
                    border: 1px solid #e3e6f0;
                    border-left: none;
                    display: flex;
                    align-items: center;

                }
               
            }
        }
    }
}