$inputOutlineColor: #7a6fbe;
$inputBackground: #eaecf4;

%common-input-style {
  border: 1px solid grey;
  padding: 10px 10px;
  border-radius: 5px;
  outline-color: blue;
  outline-width: 2px;
  cursor: pointer;
}

%table-head-row {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
}

%table-style {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

%flex-config{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}