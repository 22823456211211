.staffingBodyRow{
    padding: 1px; 
    color: #191919;
}
.singleStaffingCardData{
    background-color: white;
    position: relative;
}
.singleStaffingCardData:hover{
    background-color:#ccc9c9
}