.availability-card{
    width:230px;
    color: #0878ff;
}

.availability-date{
    height: 40px;
    background-color:#bcbcbc;
    font-size: 1.25rem;
    color:rgb(95, 95, 95);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
}
.availability-data{
    background-color:white;
    color: #0878ff;
    cursor: pointer;
}
.availability-data:hover{
    background-color:#bcbcbc;
}   
.availability-input{
    margin-left:1px; 
    margin-right:1px; 
    margin-bottom:1px; 
    margin-top:1px; 
    opacity:1;
}
.availability-input{
    display:flex;
}