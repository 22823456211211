.table-pagination {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
}

.table-input {
    display: "flex";
    align-items: "center";
}

.custom-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.custom-table-head{
    display: flex;
    cursor: pointer;
}
.custom-table td,
.custom-table th {
    border: 1px solid #757575;
    padding: 8px;
}

.custom-table tr:nth-child(odd) {
    /* background-color: #f2f2f2; */
}

.custom-table tr:hover {
    background-color: #f2f2f2;
}


.custom-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: black;
}
