.direct-invoice{
    background-color:whitesmoke;
    width:80%;
    margin:auto;
    margin-top: 5rem;

}

.direct-invoice-table{
    width: 100%; 
    margin-top: 20px;
}
.direct-invoice-table thead{
    background-color:#001940;
    color:white;
}

.direct-invoice-slip{
    font-size:20px;
    font-weight:bold;
    
}

.direct-invoice-logo{
    position:absolute;
    right: 58%;
    width: 75px;
    top: -55px;
}

.direct-invoice-table tr:nth-child(even){background-color: rgb(228, 228, 228);}
.direct-invoice-table td{
    border: 1px solid black;
}
