.table-pagination {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
}

.table-input {
    display: "flex";
    align-items: "center";
}

.custom-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    overflow-y: auto;
}

.custom-table-head {
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: bolder;
    color: black;
    align-items: center;
}
.custom-table td,
.custom-table th {
    /* border: 1px solid #ddd; */
    padding: 5px;
    /* font-size: 15px; */
    /* max-width: 175px; */
    align-items: center;
    min-width: 120px;
}

.custom-table tr td:last-child {
    /* display: flex; */
    /* word-break: break-all; */
}

.custom-table tr:nth-child(odd) {
    background-color: #f6f6f6;
}

.custom-table tr:hover {
    background-color: #e9e9e9;
}

.custom-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: black;
}

.search-cross {
    position: absolute;
    right: 50px;
    top: 3px;
}

.column-line {
    font-size: 25px;
    cursor: pointer;
    margin-left: 5px;
}
.cross-icon {
    font-size: 20px;
    cursor: pointer;
}

.input-checkbox {
    margin-right: 6px;
    margin-top: -3px;
    width:auto;
}

.input-label {
    font-size: 0.9rem;
    font-weight: 400 ;
    margin-bottom: 1px;
    display: flex;
    overflow-wrap: break-word;
}

.colums-view{
    padding-left: 10px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}


.multi-btn{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 50px; /* Vertically center the text */
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-table-footer tr:last-child { background:rgb(218, 218, 218); font-weight: 900; font-size: 15px !important; }