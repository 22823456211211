@import "../../common.scss";
.row {
  .col-12 {
    .card {
      .card-body {
        .calendar-nav-header {
          padding: 10px 0px 20px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
          }
          input {
            @extend %common-input-style;
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.fc-toolbar.fc-header-toolbar {
  display: none;
}

// Modal Header
.modal-header {
  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-close-icon {
      cursor: pointer;
    }
  }
}

// Modal Body
.modal-body-container {
  .nav-item {
    cursor: pointer;
  }
}
