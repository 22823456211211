table.t3, table.t3 th,table.t3 td {
    border: 1px solid black;
    border-collapse: collapse;
   
}
table.t5, table.t5 th,table.t5 td {
    border: 1px solid black;
    border-collapse: collapse;
   
}
table.t2 {
    margin-top: 104.84px;
}
table.t4 {
    margin-top: 200.84px;
}
table.t1 td {
    font-weight:bold;
}
th, td {
    padding: 5px;
    text-align: left;    
}