.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-circle1 {
  border-radius: 50% !important;
  height: 2.5rem;
  width: 2.5rem;
}

.notification-others-title {
  width: 15%;
  background-color: "white";
}

.notification-row {
  height: 50vh;
  overflow-y: scroll;
}

/* checkbox */

.checkbox-div {
  display: flex;
}

.checkbox-label {
  margin: 0px;
}

.checkbox-input {
  height: 15px;
}

.important-width {
  width: 14.28%;
}
.color-red {
  color: #ff7171;
}
.notification-scrolling {
  height: 80vh;
  overflow-y: scroll;
}

.notification-scrolling::-webkit-scrollbar {
  display: none;
}

.notification-scrolling {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="radio"],
input[type="checkbox"] {
  height: inherit;
}

.input-checkbox {
  display: flex;
  padding-left: 18px !important;
}
.checkbox-label {
  padding-left: 5px;
}

.notification-date {
  font-weight: 900;
  color: #31318b;
}

.notification-client {
  font-size: 14px;
  font-weight: 900;
}

.notification-shift {
  font-size: 13px;
  position: relative;
}

.notification-shift-background {
  background-color: #d7c734;
}
.notification-update-date {
  font-size: 12px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
}

.notification-late {
  background: red;
  color: white;
  padding: 0px 4px;
}

.notification-bonus {
  background-color: lightgoldenrodyellow;
  padding: 0px 5px;
  font-weight: 700 !important;
  position: absolute;
  right: 2px;
  top: 2px;
}

.notification-bonus-name {
  background-color: lightgoldenrodyellow;
  padding: 0px 5px;
  font-weight: 700 !important;
  position: absolute;
  left: 0px;
  bottom: -2px;
}

.date-width {
  width: 100%;
  padding-left: 9px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5b626b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-wrapper {
  width: 100%;
}

.tooltip {
  z-index: 9999 !important;
  width: 500px !important;
  max-width: 900px !important;
}
.tooltip-inner {
  max-width: 100% !important;
}

.breakdown-table td {
  text-align: center;
}

.breakdown-table thead {
  font-weight: 900;
  font-size: 13px;
}

.breakdown-table td {
  text-align: left;
}

.breakdown-table thead {
  font-weight: 900;
  font-size: 13px;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #8d8797;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  padding: 2px;
  color: #8d8797;
  left: -3px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  color: #8d8797;
  padding: 2px;
  left: 30px;
}

.page-content {
  min-height: 100vh;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Table for holiday and shift */
.shift-table>div:nth-child(2)>div>div>table>thead>tr>th {
  width: 50px;
  max-width: 50px;
}

.shift-table>div:nth-child(2)>div>div>table {
 width: 100% !important;
}

.holiday-date{
  width: 130px !important;
}