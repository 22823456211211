.auto-suggest{
    width: 100%;
    /* display: block; */
    /* width: 100%; */
    padding: .47rem .75rem;
    /* font-size: .8125rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    color: #bfc8e2;
    background-color: #2e3446;
    background-clip: padding-box;
    border: 1px solid #32394e;
    /* -webkit-appearance: none; */
    /* appearance: none; */
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: .25rem;
   
}
.react-autosuggest__suggestions-container{
    position:absolute;
    z-index: 1000;
    background-color:white;
    color:black;
    width: 85%;
    
    border-radius: .25rem;
    margin-top: -5px;
    
}
.react-autosuggest__suggestions-list{
    list-style-type: none;
    padding-left: 0px  !important;
    margin-left: 0px  !important;
    margin-bottom: 0px !important;

}
.react-autosuggest__suggestion{
    padding:10px 5px;
}
.react-autosuggest__suggestion:hover{
   background-color: rgb(223, 223, 223);
   border-radius: .25rem;
}